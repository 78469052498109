import { eventNames, cognitoEnv, eventSettings } from './constants';

export const getEventName = () => {
  const queryStringParams = new URLSearchParams(window.location.search);
  const redirectUri = queryStringParams.get("redirect_uri");
  let eventName = "";

  if (!redirectUri) {
    eventName = null;
  }
  else if (redirectUri.toLowerCase().includes("reinforce")) {
    eventName = eventNames.reinforce;
  }
  else if (redirectUri.toLowerCase().includes("reinvent")) {
    eventName = eventNames.reinvent;
  }

  return eventName;
};

export const getSignUpUrl = () => {
    let signUpurl = "";
    const eventName = getEventName();
    const currentEnv = cognitoEnv.get(window.location.hostname);

    if (!eventName) return '#';

    switch(currentEnv) {
        case 'test': 
        case 'stage': 
        signUpurl = `https://${eventName}2024.${currentEnv}.eventcore.com/auth/challenge?auth_type=register`;
        break;
        case 'prod': 
        signUpurl = `https://register.${eventName.replace('aws', '')}.awsevents.com/auth/challenge?auth_type=register`;
        break;
        default:
        break;
    }

    return signUpurl;
};

export const getAttendeePortalUrl = () => {
    const eventName = getEventName();
    const currentEnv = cognitoEnv.get(window.location.hostname);

    const eventConfig = eventSettings[eventName];

    const url = eventConfig.attendeePortalUrl[currentEnv];

    return url;
};