import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import { storeTokenAndRedirect } from "../lib/tokenHelper";
import { getAttendeePortalUrl, getEventName } from "../utils";
import { eventSettings } from "../constants";

import "./Error.css";

export default function Error({ userHasAuthenticated, isAuthenticated }) {
  useEffect(()=>{
    document.title = 'AWS Events | Error';
  });

  if (isAuthenticated) {
    try {
      storeTokenAndRedirect();
    } catch (e) {
      console.error(e);
    }
    return <Loading />;
  }

  return (
    <div className="Login">
      <Container>
        <Navbar />
          <div class="error">
              {/* <img src="https://awsreinvent23-stage.mpeventapps.com/includes/images/attendee-portal/2023_reInforce_Logo_Black_noDate.png" alt="" id="logo" /> */}
              <h1 class="h2"><i class="fa fa-diamond-exclamation"></i> An error has occurred</h1>
              <div class="mt-4">
                <p class="mb-3">We are sorry for any inconvenience. For assistance, please contact <a href="mailto:awsreinvent-support@amazon.com" class="text-white fw-bold">awsreinvent-support@amazon.com</a>.</p>

              </div>
          </div>

        <Footer />
      </Container>
    </div>
  );
}
