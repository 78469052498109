import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Route, Routes } from "react-router-dom";

import Login from "./pages/Login";
import Error from "./pages/Error";
import Logout from "./pages/Logout";
import Signup from "./pages/Signup";
import Reset from "./pages/Reset";
import Loading from "./components/Loading";

import "./App.css";

function App() {
  // Track if authentication is in progress
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  // Track is the user has authenticated
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  // Props that'll be passed to all the routes
  const routeProps = { isAuthenticated, userHasAuthenticated };

  useEffect(() => {
    async function onLoad() {
      try {
        // Check if the user is authenticated
        await Auth.currentSession();
        userHasAuthenticated(true);
      } catch (e) {
        if (e.code === "NotAuthorizedException") {
          setIsAuthenticating(false);
          window.location.href = "/login" + window.location.search;
        } else if (e !== "No current user") {
          alert(e);
        }
      }

      setIsAuthenticating(false);
    }

    onLoad();
  }, []);

  if (!isAuthenticating) {
    return (
        <div className="App">
            <div class="skiptocontent" id="skiptocontent">
                <a role="button" class="skiptocontent__link" href="#main">Skip to Main Content</a>
            </div>
        <Routes>
          <Route exact path="/login" element={<Login {...routeProps} />} />
          <Route exact path="/register" element={<Signup {...routeProps} />} /> 
          <Route exact path="/logout" element={<Logout {...routeProps} />} />
          <Route exact path="/reset" element={<Reset {...routeProps} />} />
          <Route exact path="/error" element={<Error {...routeProps} />} />
        </Routes>
      </div>
    );
  } else {
    return (
      <div className="App">
        <Loading />
      </div>
    );
  }
}

export default App;
