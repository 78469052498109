export const environments = { };
export const eventNames = {
    reinforce: 'awsreinforce',
    reinvent: 'awsreinvent'
};
export const hostNames = {
    test: 'd3ichewryl1jmm.cloudfront.net',
    stage: 'd34p1irbvqs9j.cloudfront.net',
};
export const cognitoEnv = new Map([
    ['localhost', 'test'],
    ['d3ichewryl1jmm.cloudfront.net' , 'test'],
    ['d34p1irbvqs9j.cloudfront.net', 'stage'],
    ['d1vboexvxhe10g.cloudfront.net','stage'],
    ['auth.awsevents.com' , 'prod']
  ]);

export const eventSettings = {
    awsreinforce: {
        showAttendeePortalButton: false,
        attendeePortalUrl: {
            test: 'https://awsreinforce24-stage.mpeventapps.com/auth/login/cognito',
            stage: 'https://awsreinforce24-stage.mpeventapps.com/auth/login/cognito',
            prod: 'https://awsreinforce24.mpeventapps.com/auth/login/cognito'
        }
    },
    awsreinvent: {
        showAttendeePortalButton: false,
        attendeePortalUrl: {
            test: 'https://awsreinvent24-dev.mpeventapps.com/attendee-portal',
            stage: 'https://awsreinvent24-stage.mpeventapps.com/attendee-portal',
            prod: 'https://awsreinvent24.mpeventapps.com/attendee-portal'
        }
    }
};